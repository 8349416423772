import './App.scss'
import './responsive.scss'
import { IntercomProvider, useIntercom } from 'react-use-intercom'
import {
  getAuth,
  onAuthStateChanged,
  signInWithCustomToken
} from 'firebase/auth'
import { auth } from './config/firebase'
import HeaderLayout from './layout/headerLayout.jsx'

import React, { useEffect, useState } from 'react'
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider
} from 'react-router-dom'
import { PageLoader } from './auth0/page-loader'
import Unauthorized from './components/Unauthorized'
import Highlights from './screens/Highlights.js'

import Dashboard from "./screens/Dashboard";
import { redirect_uris, space } from "./constants.js";
import { ApiRequests } from "./service/ApiRequests.js";
import { Button } from "react-bootstrap";
import FindOrgs from "./components/FindOrgs.jsx";
import CryptoJS from "crypto-js";
import AllProducts from "./screens/AllProducts.js";
import { useDispatch, useSelector } from "react-redux";
import { getClassessAsyncThunk, getStaffClassessAsyncThunk } from "./redux/pagesSlices/classesSlice.js";
import Countly from "./config/countly.js";

function App ({ organization }) {
  const INTERCOM_APP_ID = 'sx1ncldw'
  const secretKey = 'l0rqsua2pkxooSbEnnh7V_cFdcoa4dG9eM9fQRcY'
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('token')
  const [error, setError] = useState()
  const [roles, setRoles] = useState([])
  const [loading, setLoading] = useState(true)
  const [rolesLoading, setRolesLoading] = useState(true)
  const [user, setUser] = useState(null)
  const auth = getAuth()
  const ClassLoading = useSelector(
    (state) => state.classes.loadings?.getClassessAsyncThunk
  )
  const currentClass = useSelector((state) => state.auth.class) // Adjust this selector based on your Redux state structure

  const d = useDispatch()
  const userId = user ? user?.user_id?.toString() : ''
  const hash = CryptoJS.HmacSHA256(userId, secretKey).toString(
    CryptoJS.enc.Hex
  )

  // useEffect(() => {
  //   setRolesLoading(false);
  // }, [ClassLoading]);

  const removeTokenFromURL = () => {
    // Get the current URL
    const currentURL = new URL(window.location.href)

    // Remove the 'token' query parameter
    currentURL.searchParams.delete('token')

    // Replace the current entry in the history with the updated URL
    window.history.replaceState({}, '', currentURL.toString())
  }
  const verifyAuth = async () => {
    const customToken = localStorage.getItem('custom-token')
    const _token = token || customToken
    if (_token) {
      await auth.signOut()
      localStorage.setItem('custom-token', _token)
      setLoading(true)
      signInWithCustomToken(auth, _token)
        .then((userCredential) => {
          const user = userCredential.user
          setUser(user)
          setToken(user)
          removeTokenFromURL()
        })
        .catch((error) => {
          window.location.href =
            redirect_uris.Login +
            `signin/?redirect=${window.location.href.split('?')[0]}`
          handleError(error)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }

  const handleError = (error) => {
    if (error.response) {
      setError(error.response.data)
    } else if (error.request) {
      setError('No response received from the server.')
    } else {
      setError(error.message)
    }
  }
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        const oldSpace = JSON.parse(localStorage.getItem('space'))
        const pathArray = window.location.pathname.split('/')
        const consoleValue = pathArray[2]
        if (organization?.tenantId !== oldSpace?.tenantId) {
          window.location.href = window.location.href.replace(
            consoleValue,
            oldSpace?.displayName
          )
        }
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])
  useEffect(() => {
    verifyAuth()
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        setToken(user);

        if (
          user &&
          process.env.REACT_APP_COUNTLY_APP_ENABLED === "true" &&
          user.displayName
        ) {
          try {
            Countly.user_details({
              name: user.displayName,
              username: user.uid,
              email: user.email,
              phone: user.phoneNumber,
              picture: user.photoURL,
            });
          } catch (error) {
            console.error("Error setting countly user details:", error);
          }
        }
        //
        try {
          setRolesLoading(true)

          const memberSip = await ApiRequests.getOrganizationMembership(
            organization?.tenantId
          )

          if (
            memberSip &&
            memberSip.data &&
            Array.isArray(memberSip.data) &&
            memberSip.data.length > 0
          ) {
            setRoles(memberSip.data.map((member) => member.name))
            localStorage.setItem(
              'role',
              memberSip.data.map((member) => member.name)
            )
          }
          const profile = await ApiRequests.getProfile();
          if (!currentClass) {
            // const classes = await d(getClassessAsyncThunk());
            const classes = await d(
              memberSip?.data.some((i) => i?.name === "Admin")
                ? getClassessAsyncThunk()
                : getStaffClassessAsyncThunk()
            );
            if (!classes?.payload?.results?.length && !isToastShown.current) {
              toast.error(
                "A Space has not been created yet. Please create a Space first to proceed with the portal."
              );
              isToastShown.current = true;
              setTimeout(() => {
                window.location.href = redirect_uris?.Admin;
              }, 7000);
            }
          }
        } catch (error) {
          if (!token) {
            window.location.href =
              redirect_uris.Login +
              `signin/?redirect=${window.location.href.split('?')[0]}`
          }
        } finally {
          setRolesLoading(false)
        }
        //
      } else {
        const customToken = localStorage.getItem('custom-token')
        const _token = token || customToken
        if (!_token) {
          window.location.href =
            redirect_uris.Login +
            `signin/?redirect=${window.location.href.split('?')[0]}`
        }
        setUser(null)
      }
    })

    return () => unsubscribe()
  }, [])
  const setToken = async (user) => {
    try {
      localStorage.setItem('app-access-token-auth0', user.accessToken)
      setTimeout(() => {
        setLoading(false)
      }, 100)
    } catch (error) {
      setLoading(false)
    }
  }

  const buttonStyle = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    zIndex: 999
  }

  // const FloatingButton = () => {
  //   const { boot, shutdown, hide, show, update } = useIntercom();

  //   return (
  //     <div style={buttonStyle}>
  //       <Button variant="primary" onClick={show} className="chat-float-btn">
  //         <i style={{ color: "white" }} class="fa-solid fa-comment"></i>
  //       </Button>
  //     </div>
  //   );
  // };

  if (!organization) return <FindOrgs />

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path='/'>
        <Route
          index
          element={
            <HeaderLayout>
              <Dashboard roles={roles} />
            </HeaderLayout>
          }
        />
        <Route
          path='highlights'
          element={
            <HeaderLayout>
              <Highlights />
            </HeaderLayout>
          }
        />

        <Route
          path='products'
          element={
            <HeaderLayout>
              <AllProducts />
            </HeaderLayout>
          }
        />
        <Route path='unauthorized' element={<Unauthorized />} />
      </Route>
    ),
    { basename: `/console/${organization?.displayName ?? space}/` }
  )
  if (loading || rolesLoading) {
    return (
      <div className='page-layout'>
        <PageLoader />
      </div>
    )
  }
  if (roles.length === 0) {
    return (
      <div className='error-div'>
        <h2>403</h2>
        <h1>OOPS</h1>
        <h5 className='error-message'>
          You are not authorized for this tenant.
          <Button
            variant='link'
            as='a'
            href={redirect_uris.Login + 'signup/organization'}
          >
            Go to Organization
          </Button>
        </h5>
      </div>
    )
  }
  return (
    <>
      <IntercomProvider
        appId={INTERCOM_APP_ID}
        autoBoot={!!user}
        autoBootProps={{ userId: user?.user_id }}
      >
        <RouterProvider key={currentClass?.class_id} router={router} />
      </IntercomProvider>
    </>
  )
}
const withOrganizationData = (WrappedComponent) => {
  return (props) => {
    const [organization, setOrganization] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
      const fetchOrganization = async () => {
        try {
          const pathArray = window.location.pathname.split('/')
          const consoleValue = pathArray[2]
          if (!consoleValue) throw new Error('No console value found')
          const { data } = await ApiRequests.getOrganization(consoleValue)
          localStorage.setItem('space', JSON.stringify(data))
          setOrganization(data)
        } catch (error) {
          localStorage.removeItem('space')
        } finally {
          setLoading(false)
        }
      }

      fetchOrganization()
    }, [])

    if (loading) {
      return (
        <div className='page-layout'>
          <PageLoader />
        </div>
      )
    }

    return <WrappedComponent organization={organization} {...props} />
  }
}
export default withOrganizationData(App)
